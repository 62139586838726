.main-app-container {
  display: flex;

  .sidebar-container {
    width: 300px;
  }

  .main-content-container {
    width: 100%;
  }
}
