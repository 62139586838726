.simple-input {
  background-color: $neutralColor;
  border: none;
  border-bottom: solid 1px $primaryColor;
  padding: 6px 3px;
  color: $primaryColor;
  margin-right: 10px;
}

.dark-input {
  width: 100%;
  padding: 8px 10px;
  background-color: $primaryColor300;
  color: white;
  font-size: 16px;
  border: none;
  border-bottom: $neutralColor500 solid 1px;
  margin-bottom: 20px;

  &::placeholder {
    color: $neutralColor500;
  }

  &:focus,
  &:active {
    border: none;
    outline: none;
    border-bottom: $orange solid 1px;
  }
}

.bright-input {
  width: 100%;
  padding: 8px 10px;
  background-color: $neutralColor500;
  color: $neutralColor900;
  font-size: 16px;
  border: none;
  border-bottom: $neutralColor500 solid 1px;
  margin-bottom: 20px;

  &::placeholder {
    color: $neutralColor700;
  }

  &:focus,
  &:active {
    border: none;
    outline: none;
    border-bottom: $primaryColor solid 1px;
  }
}
