@import './variables';
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;

  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
  //   Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-family: 'Montserrat', sans-serif;

  font-weight: 400;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  // background-color: #f1efef;
  background-color: rgb(243 ,244, 246 )
}

@import './text';
@import './sidebar';
@import './mainLayout';
@import './talents';
@import './inputFields';
@import './buttons';
@import './investorProfile';
@import './talentProfile';
@import './modal';
@import './login';
@import './form';
@import './verifyEmail';
