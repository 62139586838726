.talent-profile-section {
  padding: 15px;
  .hero-video {
    img {
      width: 100%;
      max-width: 70%;
      max-height: 400px;
      object-fit: cover;
      overflow: hidden;
      border-radius: 20px;
    }
  }

  .profile-info-top-container {
    display: flex;
    padding-top: 20px;
    .profile-image {
      padding-right: 20px;
      img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .info {
      padding-left: 20px;
      padding-top: 20px;
      .tags {
        display: inline-flex;
        background-color: #2f2e2e;
        padding: 4px 8px;
        color: white;
        border-radius: 20px;
      }

      .stats {
        display: flex;
        padding: 10px 0;
        p {
          padding-right: 15px;
          font-weight: bold;
        }
      }
    }
  }

  .talent-bio-container {
    margin-bottom: 20px;
  }
  .token-info-container {
    width: 50%;
  }
}
