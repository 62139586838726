.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;

  .modal-main {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 744px;
    left: calc(50% - 372px);
    @media (max-width: 744px) {
      left: 0;
      width: 100%;
    }

    // min-height: 200px;
    max-height: 80vh;
    overflow-y: scroll;


    border-radius: 10px;
    overflow: hidden;
    background: #fff;

    .modal-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 10px 5px;
      border-bottom: solid 1px black;

      .close-action {
        position: absolute;
        right: 0px;
        top: 0px;
        padding-top: 10px;
        padding-right: 2px;

        button {
          color: red;
        }
      }
    }

    .modal-body-container {
      overflow-y: scroll;
      max-height: 70vh;
    }
  }
}

.modal-display-block {
  display: block;
}

.modal-display-none {
  display: none;
}

// .modal-title {
//   width: 90%;
//   font-size: 24px;
//   padding-bottom: 15px;
//   padding-top: 15px;
// }

// .modal-body {
//   margin-bottom: 10px;
//   font-size: 14px;
// }

// .modal-image {
//   max-width: 100%;
//   cursor: pointer;
//   padding: 10px 0;
// }

// .modal-actions {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   padding: 15px 0;
// }

//   .modal-big-button-with-subtitle {
//     display: flex;
//     flex-direction: column;
//     margin: 20px 10px;
//     padding: 10px 15px;
//     background-color: $contentBackgroundColor;
//     box-shadow: 0 4px 8px 0 #0000003d;
//     border-radius: 5px;
//     cursor: pointer;

//     .title {
//       font-weight: 700;
//       font-size: 20px;
//       padding-bottom: 4px;
//     }
//     .description {
//       color: $grayTextColor;
//     }

//     &:hover {
//       box-shadow: 0 4px 8px 0 #00000050;
//       background-color: #f1f1f1;
//     }
//   }

//   .modal-options-with-checkbox {
//     display: flex;
//     flex-direction: column;
//     margin: 8px 10px;
//     padding: 10px 15px;
//     background-color: $contentBackgroundColor;
//     box-shadow: 0 2px 4px 0 #0000002a;
//     border-radius: 5px;
//     cursor: pointer;
//     user-select: none;
//     &:hover {
//       box-shadow: 0 4px 8px 0 #00000050;
//     }
//   }
