.verify-email-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: $primaryColor;

  .logo-container {
    position: absolute;
    padding: 10px;
    img {
      width: 200px;
    }
  }
  .content-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: white;
    margin-top: 80px;
    margin: 80px 50px;
    width: 100%;
    border-radius: 20px;
    padding: 10px;

    .title {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
    .mail {
      display: flex;
      justify-content: center;
      padding: 30px;
    }
    .icon {
      font-size: 120px;
      padding: 30px;
      color: $greenColor;
    }

    form {
      width: 300px;
      padding-top: 50px;
    }
    .action-container {
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
