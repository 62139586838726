.sidebar {
  height: 100vh;
  padding: 10px;
  padding-top: 20px;

  p {
    cursor: pointer;
    padding: 10px 0;
    font-weight: bold;
  }
}
