.investor-profile-section {
  padding: 15px;
  .profile-info-top-container {
    display: flex;

    .profile-image {
      padding: 10px;
      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .info {
      padding-left: 20px;
      padding-top: 30px;
      .tags {
        display: inline-flex;
        background-color: #2f2e2e;
        padding: 4px 8px;
        color: white;
        border-radius: 20px;
      }
    }
  }

  .investor-bio-container {
    width: 70%;
    margin-bottom: 30px;
  }

  .sponsored-talents-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .talent-cards-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .talent-card {
        background-color: white;
        border-radius: 15px;
        margin: 10px;
        width: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
        cursor: pointer;
        box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          padding: 5px;
        }
      }
    }
  }
}
