.talents-overview-container {
  display: flex;
  .left-column {
    width: 70%;
    padding: 10px;

    .suggested-talents-container {
      margin-top: 10px;
      .talent-card-container {
        display: flex;
        margin: 20px 0;

        .talent-card {
          background-color: white;
          border-radius: 10;
          width: 120px;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 10px;
          box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          .profile-image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            padding: 5px;
          }
          p {
            padding: 10px 0;
          }
        }
      }
    }

    .talent-search-section {
      background-color: white;
      border-radius: 15px;
      padding: 15px;

      .search-field-container {
        padding: 10px 0;
        margin-bottom: 20px;
        border-bottom: solid #bcbaba 1px;
      }

      .search-results-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 200px;

        .talent-card {
          background-color: $neutralColor;
          border-radius: 15px;
          padding: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          display: flex;

          .profile-image-container {
            padding-right: 10px;
            img {
              width: 120px;
              height: 120px;
              border-radius: 50%;
            }
          }

          .info {
            flex: 1;
            .tags {
              display: flex;
              padding-top: 10px;
              p {
                background-color: $orange;
                color: $primaryColor;
                padding: 4px 8px;
                border-radius: 20px;
                margin-right: 10px;
                font-weight: 600;
                font-size: 14px;
              }
            }
          }
          .stats {
            width: 150px;
          }
        }
      }
    }
  }

  .right-column {
    padding: 10px;

    .summary-report-container {
      background-color: white;
      border-radius: 15px;
      padding: 15px;
      margin: 10px 0;
      box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
    }

    .supported-talents-container {
      display: flex;
      flex-direction: column;
      .talent-card {
        cursor: pointer;
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        .profile-image {
          margin-right: 15px;
          background-color: white;
          border-radius: 15px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);

          img {
            width: 90px;
            height: 90px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
