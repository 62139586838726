$neutralColor: #f1efef;

$orange: #fca243;
$greenColor: #01b23d;
$violetColor: #463182;

$primaryColor: #463182;

$primaryColor300: #7b779a;

$neutralColor500: #f1efef;
$neutralColor700: #bcbaba;
$neutralColor900: #2f2e2e;
