@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #f3f4f6;
}

.title {
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: 600;
}

.large-title-95 {
  font-size: 95px;
  font-weight: bold;
  color: #463182;
}

.large-title-60 {
  font-size: 60px;
  color: #463182;
}

.large-title-30 {
  font-size: 30px;
  font-weight: 600;
  color: black;
}

.caption-10 {
  font-size: 10px;
  font-weight: 500;
  color: white;
}

.sidebar {
  height: 100vh;
  padding: 10px;
  padding-top: 20px;
}

.sidebar p {
  cursor: pointer;
  padding: 10px 0;
  font-weight: bold;
}

.main-app-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-app-container .sidebar-container {
  width: 300px;
}

.main-app-container .main-content-container {
  width: 100%;
}

.talents-overview-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.talents-overview-container .left-column {
  width: 70%;
  padding: 10px;
}

.talents-overview-container .left-column .suggested-talents-container {
  margin-top: 10px;
}

.talents-overview-container .left-column .suggested-talents-container .talent-card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
}

.talents-overview-container .left-column .suggested-talents-container .talent-card-container .talent-card {
  background-color: white;
  border-radius: 10;
  width: 120px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  -webkit-box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.talents-overview-container .left-column .suggested-talents-container .talent-card-container .talent-card .profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 5px;
}

.talents-overview-container .left-column .suggested-talents-container .talent-card-container .talent-card p {
  padding: 10px 0;
}

.talents-overview-container .left-column .talent-search-section {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
}

.talents-overview-container .left-column .talent-search-section .search-field-container {
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: solid #bcbaba 1px;
}

.talents-overview-container .left-column .talent-search-section .search-results-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 200px;
}

.talents-overview-container .left-column .talent-search-section .search-results-container .talent-card {
  background-color: #f1efef;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.talents-overview-container .left-column .talent-search-section .search-results-container .talent-card .profile-image-container {
  padding-right: 10px;
}

.talents-overview-container .left-column .talent-search-section .search-results-container .talent-card .profile-image-container img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.talents-overview-container .left-column .talent-search-section .search-results-container .talent-card .info {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.talents-overview-container .left-column .talent-search-section .search-results-container .talent-card .info .tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
}

.talents-overview-container .left-column .talent-search-section .search-results-container .talent-card .info .tags p {
  background-color: #fca243;
  color: #463182;
  padding: 4px 8px;
  border-radius: 20px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 14px;
}

.talents-overview-container .left-column .talent-search-section .search-results-container .talent-card .stats {
  width: 150px;
}

.talents-overview-container .right-column {
  padding: 10px;
}

.talents-overview-container .right-column .summary-report-container {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  margin: 10px 0;
  -webkit-box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
}

.talents-overview-container .right-column .supported-talents-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.talents-overview-container .right-column .supported-talents-container .talent-card {
  cursor: pointer;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.talents-overview-container .right-column .supported-talents-container .talent-card .profile-image {
  margin-right: 15px;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
}

.talents-overview-container .right-column .supported-talents-container .talent-card .profile-image img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.simple-input {
  background-color: #f1efef;
  border: none;
  border-bottom: solid 1px #463182;
  padding: 6px 3px;
  color: #463182;
  margin-right: 10px;
}

.dark-input {
  width: 100%;
  padding: 8px 10px;
  background-color: #7b779a;
  color: white;
  font-size: 16px;
  border: none;
  border-bottom: #f1efef solid 1px;
  margin-bottom: 20px;
}

.dark-input::-webkit-input-placeholder {
  color: #f1efef;
}

.dark-input:-ms-input-placeholder {
  color: #f1efef;
}

.dark-input::-ms-input-placeholder {
  color: #f1efef;
}

.dark-input::placeholder {
  color: #f1efef;
}

.dark-input:focus, .dark-input:active {
  border: none;
  outline: none;
  border-bottom: #fca243 solid 1px;
}

.bright-input {
  width: 100%;
  padding: 8px 10px;
  background-color: #f1efef;
  color: #2f2e2e;
  font-size: 16px;
  border: none;
  border-bottom: #f1efef solid 1px;
  margin-bottom: 20px;
}

.bright-input::-webkit-input-placeholder {
  color: #bcbaba;
}

.bright-input:-ms-input-placeholder {
  color: #bcbaba;
}

.bright-input::-ms-input-placeholder {
  color: #bcbaba;
}

.bright-input::placeholder {
  color: #bcbaba;
}

.bright-input:focus, .bright-input:active {
  border: none;
  outline: none;
  border-bottom: #463182 solid 1px;
}

.orange-button, .red-button {
  color: white;
  background-color: #fca243;
  border: none;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 40px;
  margin: 5px 10px;
}

.orange-button:hover, .red-button:hover {
  background-color: #f57e00;
  color: white;
}

.white-round-button, .white-no-background-button, .black-no-background-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: white;
  color: #463182;
  padding: 8px 15px;
  border-radius: 40px;
  margin: 5px 10px;
  font-weight: 600;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.white-round-button:hover, .white-no-background-button:hover, .black-no-background-button:hover {
  background-color: #7b779a;
  color: white;
}

.white-no-background-button {
  background-color: transparent;
  color: white;
}

.black-no-background-button {
  background-color: transparent;
  color: black;
}

.red-button {
  background-color: #ec3c3c;
}

.investor-profile-section {
  padding: 15px;
}

.investor-profile-section .profile-info-top-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.investor-profile-section .profile-info-top-container .profile-image {
  padding: 10px;
}

.investor-profile-section .profile-info-top-container .profile-image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}

.investor-profile-section .profile-info-top-container .info {
  padding-left: 20px;
  padding-top: 30px;
}

.investor-profile-section .profile-info-top-container .info .tags {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: #2f2e2e;
  padding: 4px 8px;
  color: white;
  border-radius: 20px;
}

.investor-profile-section .investor-bio-container {
  width: 70%;
  margin-bottom: 30px;
}

.investor-profile-section .sponsored-talents-container {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.investor-profile-section .sponsored-talents-container .talent-cards-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.investor-profile-section .sponsored-talents-container .talent-cards-container .talent-card {
  background-color: white;
  border-radius: 15px;
  margin: 10px;
  width: 110px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
}

.investor-profile-section .sponsored-talents-container .talent-cards-container .talent-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 5px;
}

.talent-profile-section {
  padding: 15px;
}

.talent-profile-section .hero-video img {
  width: 100%;
  max-width: 70%;
  max-height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
  border-radius: 20px;
}

.talent-profile-section .profile-info-top-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
}

.talent-profile-section .profile-info-top-container .profile-image {
  padding-right: 20px;
}

.talent-profile-section .profile-info-top-container .profile-image img {
  width: 120px;
  height: 120px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.talent-profile-section .profile-info-top-container .info {
  padding-left: 20px;
  padding-top: 20px;
}

.talent-profile-section .profile-info-top-container .info .tags {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: #2f2e2e;
  padding: 4px 8px;
  color: white;
  border-radius: 20px;
}

.talent-profile-section .profile-info-top-container .info .stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
}

.talent-profile-section .profile-info-top-container .info .stats p {
  padding-right: 15px;
  font-weight: bold;
}

.talent-profile-section .talent-bio-container {
  margin-bottom: 20px;
}

.talent-profile-section .token-info-container {
  width: 50%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.modal .modal-main {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 744px;
  left: calc(50% - 372px);
  max-height: 80vh;
  overflow-y: scroll;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
}

@media (max-width: 744px) {
  .modal .modal-main {
    left: 0;
    width: 100%;
  }
}

.modal .modal-main .modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 5px;
  border-bottom: solid 1px black;
}

.modal .modal-main .modal-header .close-action {
  position: absolute;
  right: 0px;
  top: 0px;
  padding-top: 10px;
  padding-right: 2px;
}

.modal .modal-main .modal-header .close-action button {
  color: red;
}

.modal .modal-main .modal-body-container {
  overflow-y: scroll;
  max-height: 70vh;
}

.modal-display-block {
  display: block;
}

.modal-display-none {
  display: none;
}

.login-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
}

.login-container .left-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #463182;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}

.login-container .left-column::before {
  background: -webkit-gradient(linear, left top, right bottom, color-stop(50%, white), color-stop(50%, transparent));
  background: linear-gradient(to right bottom, white 50%, transparent 50%);
}

.login-container .left-column .logo-container {
  height: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.login-container .left-column .logo-container img {
  width: 400px;
}

.login-container .left-column .divider-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 0;
}

.login-container .left-column .divider-container div {
  height: 1px;
  width: 15vw;
  background-color: #f1efef;
  margin: 10px;
}

.login-container .left-column .username-password-container {
  width: 300px;
}

.login-container .left-column .forgot-password-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: -10px;
  cursor: pointer;
}

.login-container .left-column .actions-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}

.login-container .right-column {
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 45vw;
}

.login-container .right-column .headline-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 20px;
  margin-top: 50px;
}

.login-container .right-column .hero-image-contaier {
  overflow: hidden;
}

.login-container .right-column .hero-image-contaier img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 50vw;
  margin-left: -100px;
}

.short-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.verify-email-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #463182;
}

.verify-email-container .logo-container {
  position: absolute;
  padding: 10px;
}

.verify-email-container .logo-container img {
  width: 200px;
}

.verify-email-container .content-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: white;
  margin-top: 80px;
  margin: 80px 50px;
  width: 100%;
  border-radius: 20px;
  padding: 10px;
}

.verify-email-container .content-container .title {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.verify-email-container .content-container .mail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 30px;
}

.verify-email-container .content-container .icon {
  font-size: 120px;
  padding: 30px;
  color: #01b23d;
}

.verify-email-container .content-container form {
  width: 300px;
  padding-top: 50px;
}

.verify-email-container .content-container .action-container {
  padding-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
