// headline, caption, title, body, large title

.title {
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: 600;
}

.large-title-95 {
  font-size: 95px;
  font-weight: bold;
  color: $primaryColor;
}

.large-title-60 {
  font-size: 60px;
  // font-weight: bold;
  color: $primaryColor;
}

.large-title-30 {
  font-size: 30px;
  font-weight: 600;
  color: black;
}

.caption-10 {
  font-size: 10px;
  font-weight: 500;
  color: white;
}
