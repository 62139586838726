.orange-button {
  color: white;
  background-color: $orange;
  border: none;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 40px;
  margin: 5px 10px;
  &:hover {
    background-color: #f57e00;
    color: white;
  }
}

.white-round-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: $primaryColor;

  padding: 8px 15px;
  border-radius: 40px;
  margin: 5px 10px;

  font-weight: 600;
  border: none;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: $primaryColor300;
    color: white;
  }
}

.white-no-background-button {
  @extend .white-round-button;

  background-color: transparent;
  color: white;
}

.black-no-background-button {
  @extend .white-round-button;

  background-color: transparent;
  color: black;
}

.red-button {
  @extend .orange-button;
  background-color: #ec3c3c;
}
