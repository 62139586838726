.login-container {
  display: flex;
  height: 100vh;

  .left-column {
    display: flex;
    flex-direction: column;
    // width: 55vw;
    background-color: $violetColor;
    align-items: center;

    // clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);

    &::before {
      background: linear-gradient(
        to right bottom,
        rgba(255, 255, 255, 1) 50%,
        transparent 50%
      );
    }

    overflow: hidden;
    .logo-container {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 400px;
      }
    }

    .divider-container {
      display: flex;
      align-items: center;
      padding: 20px 0;

      div {
        height: 1px;
        width: 15vw;
        background-color: $neutralColor500;
        margin: 10px;
      }
    }

    .username-password-container {
      width: 300px;
    }

    .forgot-password-container {
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      cursor: pointer;
    }

    .actions-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }

  .right-column {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    width: 45vw;
    .headline-container {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 20px;
      margin-top: 50px;
    }

    .hero-image-contaier {
      overflow: hidden;
      img {
        object-fit: cover;
        width: 50vw;
        margin-left: -100px;
      }
    }
  }
}
